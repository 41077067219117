const storageKey = `__favorites`;

export function getFavoritesAnonymous() {
  let favorites;

  try {
    favorites = JSON.parse( localStorage.getItem(storageKey) );
  } catch (error) {
    console.error('Unable to read favorites from localstorage');
  }

  return favorites || [];
}

export function saveFavoritesAnonymous(favorites) {
  try {
    localStorage.setItem(storageKey, JSON.stringify(favorites));
  } catch (error) {
    console.error('Unable to write favorites to localstorage');
  }
}
