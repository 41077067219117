import { stopPropagation } from '@entegral/helpers.react/events/stop-propagation';
import { getFavoritesAnonymous, saveFavoritesAnonymous } from '../../utility/favorites';

const Classnames = {
  active: `favorite-toggle_state_active`,
};

const Selectors = {
  root: `.favorite-toggle`,
};

const Attributes = {
  id: `data-id`,
};

const Regexps = {
  active: new RegExp(` *${Classnames.active}`, `ig`),
};

let buttons = document.querySelectorAll(Selectors.root);
let favorites = getFavoritesAnonymous();

for (let button of buttons) {
  bindFavoriteButton(button, favorites);
}

function bindFavoriteButton(button, favorites) {
  const propertyId = button.getAttribute(Attributes.id);

  let isFavorite = false;

  if (favorites.indexOf(propertyId) !== -1) {
    isFavorite = true;
    toggleButton(button, isFavorite);
  }

  button.addEventListener('click', event => {
    stopPropagation(event);

    isFavorite = !isFavorite;
    toggleButton(button, isFavorite);

    if (isFavorite) {
      favorites.push(propertyId);
    } else {
      favorites.splice( favorites.indexOf(propertyId), 1 );
    }

    saveFavoritesAnonymous(favorites);
  });
}

function toggleButton(button, isFavorite) {
  if (isFavorite) button.className += ` ${Classnames.active}`;
  else button.className = button.className.replace(Regexps.active, ``);
}


